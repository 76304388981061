'use strict';

/*import bluebird from 'bluebird';

bluebird.config({
	warnings: false,
	longStackTraces: false
});*/

let IEReadiness;

// IE detection
if("ActiveXObject" in window) {
	if(!window.Promise) {
		//window.Promise = bluebird;
	}
	// dynamic load polyfill for IE browser
	// https://webpack.js.org/api/module-methods/#import-
	// https://webpack.js.org/guides/code-splitting/#dynamic-imports
	IEReadiness = import(/* webpackChunkName: "babel-polyfill" */ 'babel-polyfill')
		.then(polyfill => {
			// to avoid error onclick svg element on IE
			// https://github.com/Pomax/react-onclickoutside#ie-does-not-support-classlist-for-svg-elements
			if (!("classList" in SVGElement.prototype)) {
				Object.defineProperty(SVGElement.prototype, "classList", {
					get() {
						return {
							contains: className => {
								return this.className.baseVal.split(" ").indexOf(className) !== -1;
							}
						};
					}
				});
			}
		})
		.catch(err => {
			alert('load polyfill failed:' + err);
		});
} else {
	IEReadiness = window.Promise.resolve();
}

export default IEReadiness;
