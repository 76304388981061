import { connect } from 'react-redux';
import Footer from './footer';
import {
	acceptChat,
	changePickupNext,
	activateChatSourceConfig
} from '../../redux/actions/async/hmf';
import {
	agentStatistics,
} from '../../redux/actions/async/workflow';

const mapStateToProp = store => {
	const s = {
		pickUpNext: store.app.header.uiData.pickUpNext,
		acceptChat: store.app.header.acceptChat.accept,
		agentStatistics: store.app.workflow.agentStatistics
	};
	return s;
};

const mapCallbacks = (dispatch) => {
	return {
		onTogglePickUpNext: (active) => {
			dispatch(changePickupNext(active));
		},
		onToggleAcceptChat: (active) => {
			dispatch(acceptChat(active));
		},
		getAgentStats: () => {
			dispatch(agentStatistics());
		},
		onHandleChatConfig: () => {
			dispatch(activateChatSourceConfig());
		}
	}
};

const FooterCtnr = connect(mapStateToProp, mapCallbacks)(Footer);

export default FooterCtnr;
