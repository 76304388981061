var webserverRoot = window.location.protocol + "//" +window.location.hostname + "/Cention/web/";
import React from "react";
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import SidebarSettingsList from '../../reactcomponents/SidebarSettingsList';
import ToggleSwitch from '../../components/v5/ToggleSwitch';
import {I} from './config.js';
import InternalChat from '../../views/v5/chatCtnr';

class Footer extends React.Component {
	componentDidMount() {
		if(features["admin.agent-statistics"]) {
			this.props.getAgentStats();
		}
	}
	handlePickUpNext = (activate) => {
		this.props.onTogglePickUpNext(activate);
	}
	handleToggleChat = (activate) => {
		this.props.onToggleAcceptChat(activate);
	}
	renderAgentStats() {
		let today, week, month, averageToday, averageWeek, averageMonth;
		if(this.props.agentStatistics.data &&
			this.props.agentStatistics.data.agentStatistics) {
			let agentStats = this.props.agentStatistics.data.agentStatistics;
			today = agentStats.todaysList;
			week = agentStats.weeklist;
			month = agentStats.monthlist;
			averageToday = agentStats.averagetoday;
			averageWeek = agentStats.averageweek;
			averageMonth = agentStats.averagemonth;
		}
		return (
			<div className="handling-bar">
				{
					features["admin.agent-statistics"] &&
						<ul>
							<li><b>{I('Handled errands:')}</b> {I('Today:')} {today} &nbsp;{I('This week:')} {week} &nbsp;{I('This month:')} {month}</li>
							<li><b>{I('Average handling time:')}</b> {I('Today:')} {averageToday} &nbsp;{I('This week:')} {averageWeek} &nbsp;{I('This month:')} {averageMonth}</li>
						</ul>
				}
			</div>
		);
	}
	render() {
		const p = this.props;
		var chatToggle;
		if(externalqueue.isExternal && externalqueue.isChat){
			chatToggle =  (
				<div>
					<div className="extq-chat-footer">
						<SidebarSettingsList className="toggle-box">
							<ToggleSwitch
								id="chat-styler"
								name="chat"
								config={false}
								label={I("Chat")}
								handleToggle={this.handleToggleChat}
								checked={p.acceptChat}
								handleConfig={p.onHandleChatConfig}
							/>
						</SidebarSettingsList>
					</div>
				</div>
			);
		}
		return (
			<div className={p.class}>
				{chatToggle}
				<div id="mobile-fixed-footer">
					<SidebarSettingsList>
						<ToggleSwitch id={"pick-up_next-styler"} name={"pick-up_next"} label={I("Pick-up next")} handleToggle={this.handlePickUpNext} checked={p.pickUpNext} />
						<ToggleSwitch id={"chat-styler"} name={"chat"} config={false} label={I("Chat")} handleToggle={this.handleToggleChat} checked={p.acceptChat} handleConfig={p.onHandleChatConfig} />
					</SidebarSettingsList>
				</div>
				{features['chat'] && features['chat.group-chat-with-agent'] ? <InternalChat /> : null}
				{this.renderAgentStats()}
			</div>
		)
	}
}
export default Footer;
