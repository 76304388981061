import { connect } from 'react-redux';
import { branch, renderNothing } from 'recompose';
import { push } from './utils';
import Header from './header';
import { I } from './config.js';
import { PostIframeLink } from '../v5/utils';
import AlertConfirm from '../../reactcomponents/AlertConfirm';
import { ToastAlert } from '../../reactcomponents/Modal';
import NotificationList from '../../components/v5/NotificationList';
import {AnnoucementRenderer} from '../../components/v5/Annoucement';
import ChatSourceConfig from '../../components/v5/ChatSourceConfig';
import HotkeysHelper from '../../reactcomponents/Hotkeys';
import { isTwilioCallEnabled } from '../../redux/selectors/server';
import { SIP_CONNECTED } from '../../common/v5/callConstants.js';
import {
	callStatusMemoize
	, isCallPadPopupShown
	, selectedAudioInputMemo
	, showSipIncomingPopup
	, showSipOutgoingPopup
	, sipCallStatus
	, sipCallConn
	, sipCallIsRecording
	, sipGetXferRef
	, sipCallShowAgentList
	, sipCallGetAgentList
	, sipCallShowDtmfKeypad
	, sipCallCurrentTransferMode
	, sipCallTimer
	, sipCallTransferStatus
	, sipCallTransferTargetNumber
	, sipTransferCallTargetAvatar
	, sipCallTransferIsExternal
	, sipGetCurrentEid
	, sipMakeCallCurrentErrand
	, sipGetSnoopDisplayString
} from '../../redux/selectors/call';
import {
	toggleCallPadPopup
	, updateAudioInput
	, sipMakeCallFromErrand
	, resetOutboundErrandId
} from '../../redux/actions/call';
import {
	alertConfirmButtonClick,
	alertConfirmClosed,
	closePostPreview,
	popErrorOnly,
	toggleHeaderArrow,
	changeUserStatus,
	toggleAlertConfirm,
	togglePopAlert,
	toggleHotkeysHelper,
	storeCurrentHotkey,
	setHotkeyList,
	setHotkeyActive,
	toggleActiveHotkeyDialog,
	toggleShowAllNotification,
	showChatSourceConfig,
	toggleChatSource,
	updateToastList,
	hidePopupNotification,
	toggleShowAnnouncement,
} from '../../redux/actions/hmf';
import {
	agentStatusOnLoad,
	hotkeysAction,
	optionalConfirm,
	reloadAllLoadedOnce,
	stopPeriodicAgentStatus,
	updateAgentStatus,
	updateChatSourceConfig,
	acceptChat,
	agentPing,
	startPeriodicAutomaticLogout,
	stopPeriodicAutomaticLogout
} from '../../redux/actions/async/hmf';
import {
	checkBrowser
	, onceOutboundPhones
	, makeOutboundAPICall
	, makeHangupAPICall
} from '../../redux/actions/async/call';
import {
	answerIncomingSip
	, closeIncomingSip
	, onHoldSIPCall
	, onHoldSIPTransferCall
	, makeCommonAPICall
	, sipColdTransfer
	, sipWarmTransfer
	, sipGetAgentList
	, showSipAgentList
	, resetCallTimer
	, selectTransferMode
	, showDtmfKeyboard
	, closeXferSip
	, showExtTransferKeypad
	, toggleSipOutgoingPopup
	, stopAutoRecording
	, playBeepNotif
} from '../../redux/actions/async/sippRtc';
import {
	postDownloadOnEnd,
	postDownloadOnError,
	forwardErrandToAgent,
	forwardManualSipToAgent,
	forceSaveErrand,
	submitManualErrand,
	findAndOpenLastErrandInThread,
	fetchContactBook,
	confirmationYes,
	saveAsEmlClick,
	loadAndOpenErrandFromSearch,
	finalizeErrandWarmTransfer,
	closeManualCallView,
	fetchAnnouncement,
	loadAndOpenErrand
} from '../../redux/actions/async/errand';
import { cancelConfirm, showContactBook, expandHideOption, expandCollabQueryThread, selectShowReply } from '../../redux/actions/errand';
import {
	handleSearchTextChange,
	doGlobalSearchByWS,
	handleHeaderSearchHide,
	handleSearchSpinner
} from '../../redux/actions/search';
import {
	changeAdminView,
	showAgentPreferences
} from '../../redux/actions/admin';
import {
	resetWorkflowView,
	toggleWorkflowPopup,
	toggleManualOrCallPopup
} from '../../redux/actions/workflow';
import {
	openSingleIM
} from "../../redux/actions/async/internalMessages";
import {
	statisticsOnLoad,
	loadSavedReport
} from "../../redux/actions/async/statistics";
import {
	replyExpertQuery,
	multiDispatchesEE,
	fetchEEThread
} from '../../redux/actions/async/collaborate';
import {
	setupCollaborationQuery
} from '../../redux/actions/collaborate';
import {
	agentNameSelector,
	agentStatusSelector,
	getAgentStatusData,
	getAgentStatusById,
	getAgentStatusByName,
	getValidKeysByViewsSelector,
	hasChatSelector,
	hasFBChatSelector,
	hasTwitterChatSelector,
	hasChatEnabledSelector,
	hasFBChatEnabledSelector,
	hasTwitterChatEnabledSelector,
	hasLINEChatSelector,
	hasLINEChatEnabledSelector,
	hasWAChatSelector,
	hasWAChatEnabledSelector,
	hasTGChatSelector,
	hasTGChatEnabledSelector,
	hasVBChatSelector,
	hasVBChatEnabledSelector,
	hasIGChatSelector,
	hasIGChatEnabledSelector,
	hasSipVoiceSelector,
	hasSipVoiceEnabledSelector,
	hasEmailEnabledSelector,
} from '../../redux/selectors/hmf';
import {
	agentTimezoneOffsetSelector,
	currentCipherKeyMemoize,
	chatHasCallbackSelector,
	announcementAttachmentSelector
} from '../../redux/selectors/errand';
import {
	BTN_SAVE_AS_EML,
	POPBOX_ALERT,
	POPBOX_CONFIRM,
	POPBOX_CUSTOM,
	POPBOX_AUTO_LOGOUT,
	ME_CREATE,
	ME_CREATE_AS_MY,
	MP_MAXIMIZE,
	MP_NONE,
	ACCOUNTS_VIEW_SLICE,
	RPLY_COLLABORATE
} from '../../common/v5/constants';
import {
	startRecordingSip
	, stopRecordingSip
	, pauseRecordingSip
} from './webRtcVoice';
import {
	GLOBAL_SEARCH_FROM_HEADER
} from '../../redux/constants/constants';
import { wsEvent } from '../../redux/actions/websocket';
import { isCallMemoize, getCallCreatedId, manualCallMinimize } from '../../redux/selectors/manual';
import { renderSkeletonHeader } from '../../components/v5/SkeletonFrames'

const mapAlertConfirm = (state, props) => {
	const alert = state.app.header.uiData.alert
	let s = {
			show: alert.show,
			text: alert.text,
			type: alert.type,
			withCopy: alert.withCopy,
			copyAbleTxt: alert.copyAbleTxt
		};
	switch(s.type) {
		case POPBOX_CONFIRM:
			s.mainText = I('Yes');
			s.secondText = I('No');
			break;
		case POPBOX_CUSTOM:
			s.buttons = alert.buttons;
			break;
		case POPBOX_ALERT:
			s.secondText = I('Ok');
			break;
		case POPBOX_AUTO_LOGOUT:
			s.mainText = I('Logout');
			s.secondText = I('Cancel');
			break;
	}
	return s;
};

const mapAlertConfirmCallbacks = dispatch => {
	return {
		onButtonClick: (type, deferType) => {
			dispatch(alertConfirmButtonClick(type, deferType));
		},
		onConfirm: () => {
			dispatch(confirmationYes());
		},
		onCancel: isConfirm => {
			if(isConfirm) {
				dispatch(cancelConfirm());
			} else {
				dispatch(togglePopAlert());
			}
		},
		onToggleShow: state => {
			dispatch(toggleAlertConfirm(state));
		},
		onClosed: () => {
			dispatch(alertConfirmClosed());
		}
	};
};

const mapToastState = (state, props) => {
	const ui = state.app.header.uiData
	let s = {
		list: ui.toastList,
		// hidden:
	}
	return s;
};

const mapToastCallbacks = dispatch => {
	return {
		onUpdateToastList: (list) => {
			dispatch(updateToastList(list));
		},
	};
};

export const AlertConfirmCtnr = connect(
	mapAlertConfirm
	, mapAlertConfirmCallbacks
)(AlertConfirm);

export const ToastAlertCtnr = connect(
	mapToastState
	, mapToastCallbacks
)(ToastAlert);

const mapStatePostIframeLink = (state, props) => ({
	src: state.app.header.postSource
	, show: state.app.header.uiData.postPreviewIsOpened
});

const mapCallbackPostIframeLink = dispatch => {
	return {
		onBeforeStart: format => {
			// console.log("dbg: download before start!", format);
		}
		, onClick: (id, ...args) => {
			if (id === BTN_SAVE_AS_EML) {
				// TODO: allow save as eml inside preview frame but this
				// requires seperate iframe container.
				// dispatch(saveAsEmlClick());
			}
		}
		, onClose: () => {
			dispatch(closePostPreview());
		}
		, onEnd: result => {
			// console.log("dbg: download end!");
			dispatch(postDownloadOnEnd(result));
		}
		, onError: err => {
			// console.log("dbg: download end with error!", err);
			dispatch(postDownloadOnError(err.reason));
		}
	};
};

export const PostIframeLinkCtnr = connect(
	mapStatePostIframeLink
	, mapCallbackPostIframeLink
)(PostIframeLink);

const mapHotkeys = (state, props) => {
	const hks = state.app.header.uiData, hotkeyId = hks.hotkeyId, hotkeysHelper = hks.hotkeysHelper, hotkeysSet = hks.hotkeysParam,
	hotkeys = hks.hotkeys, hotkeyDialog = hks.hotkeyDialog, wfs = state.app.workflow.fetchWfSettings;
	let hotkeyMod = "";

	if(wfs && wfs.data){
		hotkeyMod = wfs.data.hotkeyControls;
	}

	let s = {
		id: hotkeyId,
		mod: hotkeyMod,
		show: hotkeysHelper,
		keys: getValidKeysByViewsSelector(state),
		params: hotkeysSet,
		hotkeys: hotkeys,
		hotkeyDialog: hotkeyDialog
	}
	return s;
};

const mapHotkeysCallbacks = dispatch => {
	return {
		onShowHelper: (show) => {
			dispatch(toggleHotkeysHelper(show));
		},
		onEnter: (act) => {
			dispatch(hotkeysAction(act));
		},
		onChangeKeys: (keys) => {
			dispatch(storeCurrentHotkey(keys));
		},
		setHotKeyList: (keys) => {
			dispatch(setHotkeyList(keys));
		},
		setHotkeyActive: (toggle) => {
			dispatch(setHotkeyActive(toggle));
		},
		setActiveHotkeyDialog: (toggle) => {
			dispatch(toggleActiveHotkeyDialog(toggle));
		}
	};
};

export const HotkeysHelperCntr = connect(mapHotkeys,
	mapHotkeysCallbacks)(HotkeysHelper);

const mapNotificationState = store => {
	const notification = store.app.notification;
	return {
		data: notification.messages,
		show: notification.uiData.showAll,
		agentTimezoneOffset: agentTimezoneOffsetSelector(store),
		tab: notification.uiData.tab,
	};
}

const mapNotificationDispatch = (dispatch) => {
	return {
		onClose: () => {
			dispatch(toggleShowAllNotification(false));
		},
		openLastErrandInThread: (id) => {
			dispatch(findAndOpenLastErrandInThread(id));
		},
		openErrand: (id) => {
			dispatch(loadAndOpenErrandFromSearch(id));
		},
		openMessage: (id) => {
			dispatch(openSingleIM(id));
		},
		openStatistics: id => {
			dispatch(statisticsOnLoad())
			.then(result => {
				dispatch(loadSavedReport(id));
			});
		},
		openAccounts: (id, link) => {
			let view = ACCOUNTS_VIEW_SLICE[id];
			dispatch(push(link)).then(() => dispatch(changeAdminView(view)));
		},
		openAnnouncement: (id) => {
			dispatch(fetchAnnouncement(id))
			dispatch(toggleShowAnnouncement(true));
		}
	}
}

export const NotificationListCtnr = connect(mapNotificationState, mapNotificationDispatch)(NotificationList);

const mapAnnouncementState = store => {
	const notification = store.app.notification;
	return {
		announcementData: notification.uiData.announcement,
		announcementAttachment: ( (cflag.IsActive("2023-11-10.CEN-1788.announcement.upload.attachment")) ?
			announcementAttachmentSelector(store) : []),
		show: notification.uiData.showAnnouncement,
		notificationAttachment: notification.uiData.announcement.notificationAttachment
	};
}

const mapAnnouncementDispatch = (dispatch) => {
	return {
		onCloseAnnouncement: () => {
			dispatch(toggleShowAnnouncement(false));
		},
	}
}

export const AnnouncementModalCtnr = connect(mapAnnouncementState,
	mapAnnouncementDispatch)(AnnoucementRenderer);

const mapChatSourceState = store => {
	const wfs = store.app.workflow.fetchWfSettings;
	let isQueuedManaged = false;
	if(wfs && wfs.data){
		isQueuedManaged  = wfs.data.queuedAssignNonRT;
	}
	return {
		show: store.app.header.uiData.showChatSourceConfig,
		hasChat: hasChatSelector(store),
		hasFacebook: hasFBChatSelector(store),
		hasTwitter: hasTwitterChatSelector(store),
		hasLINEChat: hasLINEChatSelector(store),
		hasWAChat: hasWAChatSelector(store),
		hasTGChat: hasTGChatSelector(store),
		hasVBChat: hasVBChatSelector(store),
		hasIGChat: hasIGChatSelector(store),
		hasSipVoice: hasSipVoiceSelector(store),
		isQueuedManaged: isQueuedManaged,
		enabledChat: hasChatEnabledSelector(store),
		enabledFacebook: hasFBChatEnabledSelector(store),
		enabledTwitter: hasTwitterChatEnabledSelector(store),
		enabledLINEChat: hasLINEChatEnabledSelector(store),
		enabledWAChat: hasWAChatEnabledSelector(store),
		enabledTGChat: hasTGChatEnabledSelector(store),
		enabledVBChat: hasVBChatEnabledSelector(store),
		enabledIGChat: hasIGChatEnabledSelector(store),
		enabledSipVoice: hasSipVoiceEnabledSelector(store),
		enabledEmail: hasEmailEnabledSelector(store),
		chatConfig: store.app.header.chatSource,
	};
}

const mapChatSourceDispatch = (dispatch) => {
	return {
		onToggle: (serviceType, enable) => {
			dispatch(toggleChatSource(serviceType, enable));
		},
		onSave: (list) => {
			dispatch(updateChatSourceConfig(list));
		}
	}
}

export const ChatSourceConfigCtnr = connect(mapChatSourceState, mapChatSourceDispatch)(ChatSourceConfig);

const getPage = store =>{
	let route = store.router;
	if(typeof route !== 'undefined'){
		if(typeof route.location !== 'undefined' && route.location.pathname){
			return route.location.pathname;
		}
	}
	return "";
}
const mapStateToProp = (store, props) => {
	const wfs = store.app.workflow.fetchWfSettings;
	const pageId= wfs?.data?.pageId;
	const search = store.app.search;
	const isCall = isCallMemoize(store);
	const m = (isCall ? store.app.errand.ui.manualCall : store.app.errand.ui.manual);
	const mc = store.app.errand.ui.manualCall;
	return {
		agentStatus: agentStatusSelector(store),
		agentStatusById: getAgentStatusById(store),
		agentStatusByName: getAgentStatusByName(store),
		agentStatusList: getAgentStatusData(store),
		callEnabled: isTwilioCallEnabled(store),
		callPadPopupShown: isCallPadPopupShown(store),
		callStatus: callStatusMemoize(store),
		uiData: store.app.header.uiData,
		notification: store.app.notification,
		selectedAudioInput: selectedAudioInputMemo(store),
		showSipIncomingPopup: showSipIncomingPopup(store),
		showSipOutgoingPopup: showSipOutgoingPopup(store),
		sipCallStatus: sipCallStatus(store),
		sipCallConn: sipCallConn(store),
		sipCallIsRecording: sipCallIsRecording(store),
		sipXferRefId: sipGetXferRef(store),
		sipCallShowAgentList: sipCallShowAgentList(store),
		sipShowDtmfKeypad: sipCallShowDtmfKeypad(store),
		sipCallAgentList: sipCallGetAgentList(store),
		sipCallCurrentTransferMode: sipCallCurrentTransferMode(store),
		sipCallTransferStatus: sipCallTransferStatus(store),
		sipCallTransferTargetNumber: sipCallTransferTargetNumber(store),
		sipCallTransferIsExternal: sipCallTransferIsExternal(store),
		sipTransferTargetAvatar: sipTransferCallTargetAvatar(store),
		sipCallTimer: sipCallTimer(store),
		sipSnoopDisplayString: sipGetSnoopDisplayString(store),
		sipChatCallbackEnabled: chatHasCallbackSelector(store),
		manualDispId: m.createdId,
		manualEid: m.newErrandId,
		mCipherKey: m.cipherKey,
		manualCallDispId: mc.createdId,
		manualCallEid: mc.newErrandId,
		mCallCipherKey: mc.cipherKey,
		agentTimezoneOffset: agentTimezoneOffsetSelector(store),
		agent: agentNameSelector(store),
		menu: store.server.menu.rightMenu,
		activeView: store.app.header.activeView,
		searchText: search.inputs.headerText,
		showResultBox: search.inputs.showResultBox,
		searchResults: search.results,
		pageAddress: getPage(store),
		pageId: pageId,
		isSearching: search.inputs.searchOngoing,
		eid: store.app.errand.currentErrand.id,
		activeCreatedErrand: getCallCreatedId(store),
		sipCurrentEid: sipGetCurrentEid(store),
		errandData: store.app.errand.basic.data,
		newIMCount: store.app.internalMessage.counters.newIM.count,
		sipServerUrl: store.app.workflow.fetchWfSettings.data.sipServerUrl,
		sipServerName: store.app.workflow.fetchWfSettings.data.sipServerName,
		aventaEnabled: store.app.workflow.fetchWfSettings.data.aventaEnabled,
		sipAllowRecord: store.app.workflow.fetchWfSettings.data.sipAllowRecord,
		sipHideColdTransfer: store.app.workflow.fetchWfSettings.data.sipHideColdTransfer,
		sipHideWarmTransfer: store.app.workflow.fetchWfSettings.data.sipHideWarmTransfer,
		disableActiveSearch: store.app.workflow.fetchWfSettings.data.disableActiveSearch,
		viewSingleErrandOnly: store.app.workflow.ui.viewSingleErrandOnly,
		isManualCallMinimize: manualCallMinimize(store),
		sipMakeCallCurrentErrand: sipMakeCallCurrentErrand(store),
		currentCipherKey: currentCipherKeyMemoize(store)
	};
};
var typingTimer;
var doneTypingInterval = 500;
const mapCallbacks = (dispatch) => {
	return {
		onClickCallIcon: showState => {
			dispatch(toggleCallPadPopup(showState));
		},
		onDoubleClickHidden: e => {
			dispatch(reloadAllLoadedOnce());
		},
		onLoad: () => {
			if (process.env.NODE_ENV !== 'production') {
				console.log("dbg: header componentDidMount");
			}
		},
		onLoadCall: ws => dispatch(checkBrowser(ws)).then(audioInputListS => {
			if (audioInputListS) {
				dispatch(onceOutboundPhones());
			}
			return audioInputListS;
		}),
		onPopAlert: (...args) => dispatch(popErrorOnly(...args)),
		onPopupAudioInputSelection: (...args) => dispatch(optionalConfirm(...args)),
		onSelectAudioInput: selected => { dispatch(updateAudioInput(selected)) },
		onToggleArrow: (key) => {
			dispatch(toggleHeaderArrow(key));
		},
		onStatusLoad: (isAventa) => {
			dispatch(agentStatusOnLoad())
			.then(data =>{
				if(isAventa){
					if(data != "loggedout"){
						dispatch(toggleChatSource(
								Workflow.Errand.SERVICE_AVENTA, true));
					}
				}
			});
			if(autoLogout != undefined && autoLogout.enabled ){
				dispatch(agentPing())
				.then(() => {
					dispatch(startPeriodicAutomaticLogout())
				})
			}
		},
		onUnload: () => {
			console.log("dbg: header can not unmount at v5 page. check ws.");
			dispatch(stopPeriodicAgentStatus());
			dispatch(stopPeriodicAutomaticLogout())
		},
		onUpdateStatus: (updateStatus) => {
			dispatch(updateAgentStatus(updateStatus));
		},
		onWSEvent: (packet) => {
			dispatch(wsEvent(packet));
		},
		onMainView: () => {
			dispatch(resetWorkflowView());
		},
		onDismissNotificationMessage: (id) => {
			dispatch(dismissNotificationMessage(id));
		},
		onUpdateNotificationMessageCount: (id) => {
			dispatch(updateNotificationMessageCount(id));
		},
		handleKeyUpSearchBox: (e, setHeaderDropdown)=>{
			if (typingTimer) clearTimeout(typingTimer);
			if(e.target.value !== ""){
				dispatch(handleSearchSpinner(true));
				typingTimer = setTimeout(()=>{
					dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_HEADER,
						setHeaderDropdown));
				}, doneTypingInterval);
			}
		},
		handleKeyDownSearchBox: (e) =>{
			clearTimeout(typingTimer);
			if(e.keyCode === 8 || e.keyCode === 46){ //backspace, delete
				dispatch(handleSearchTextChange('header', e.target.value));
			}else if(e.keyCode === 27){ //esc
				dispatch(handleSearchTextChange('header', ""));
				dispatch(handleHeaderSearchHide());
			}
		},
		handleChangeSearchBox: (e) =>{
			e.preventDefault();
			let val = e.target.value;
			dispatch(handleSearchTextChange('header',val));
		},
		handleSearchButton: (e, val) =>{
			console.log('button',e);
			dispatch(handleSearchSpinner(true));
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_HEADER, true));
		},
		handleHeaderSearchDD: (val) =>{
			dispatch(handleHeaderSearchHide(val));
		},
		onHidePopupNotification: (id) => {
			dispatch(hidePopupNotification(id));
		},
		onShowAllNotification: () => {
			dispatch(toggleShowAllNotification(true));
			dispatch(toggleShowAnnouncement(false));
		},
		openErrand: (id) => {
			dispatch(loadAndOpenErrandFromSearch(id));
			dispatch(handleHeaderSearchHide());
		},
		openCollaReplyPanel: (eId, tId, qId, isReply) => {
			dispatch(loadAndOpenErrand(eId));
			dispatch(multiDispatchesEE(eId))
			.then(() => {
				dispatch(fetchEEThread(tId, eId)).then(() => {
					dispatch(expandCollabQueryThread(tId, true));
					if(!isReply) {
						dispatch(setupCollaborationQuery(tId, qId));
					} else {
						dispatch(selectShowReply(RPLY_COLLABORATE,true));
					}
					dispatch(expandHideOption('collaboration', eId, true));
				});
			})
			dispatch(handleHeaderSearchHide());
		},
		OpenLastErrandInThread: (id) => {
			dispatch(findAndOpenLastErrandInThread(id));
			dispatch(handleHeaderSearchHide());
		},
		openMessage: (id) => {
			dispatch(openSingleIM(id));
			dispatch(handleHeaderSearchHide());
		},
		answerSipCall: (conn, xferRefId) => {
			dispatch(answerIncomingSip(conn, xferRefId));
		},
		answerTransferCall: (conn, eid) => {
			dispatch(sipGetAgentList(conn, eid));
			if(features['sip.allow-external-forward']) {
				dispatch(showContactBook(true));
				dispatch(fetchContactBook(""));
				if(features['sip.show-forward-dialer']) {
					dispatch(showExtTransferKeypad(true));
				}
			}
		},
		toggleTransferAgentList: (toggle) => {
			dispatch(showSipAgentList(toggle));
		},
		setTransferMode: (mode) => {
			dispatch(selectTransferMode(mode));
		},
		handleColdTransferToAgent: (eid, targetAgentId, sipId, conn,
			displayId, mCipherKey, isManual) => {
			if(eid != 0){
				if(isManual == true){
					dispatch(submitManualErrand(ME_CREATE, ME_CREATE_AS_MY,
						true, true))
					.then(res => {
						dispatch(forwardManualSipToAgent(eid, targetAgentId,
							mCipherKey))
						.then(data => {
							dispatch(sipColdTransfer(conn, sipId, targetAgentId,
							displayId, false, true));
						});
					});
				} else {
					stopAutoRecording();
					dispatch(forceSaveErrand())
					.then(res => {
						dispatch(forwardErrandToAgent(eid, targetAgentId,true))
						.then(data => {
							dispatch(sipColdTransfer(conn, sipId, targetAgentId,
							displayId, false, false));
						});
					});
				}
			} else {
				//when no errand created
				dispatch(sipColdTransfer(conn, sipId, targetAgentId,
					displayId, false, false));
			}
		},
		handleWarmTransferToAgent: (eid, targetAgentId, sipId, conn,
            displayId, mCipherKey, isManual) => {
            dispatch(sipWarmTransfer(conn, sipId, targetAgentId,
                displayId, eid, mCipherKey, isManual));
        },
		handleFinalizeWarmTransferToAgent: (eid, displayId, conn,
			mCipherKey, isManual, isExternalTransfer) => {
            dispatch(finalizeErrandWarmTransfer(eid, displayId, conn,
				mCipherKey, isManual, isExternalTransfer));
		},
		pauseSipTransferCall: (eid, conn, isHold) =>{
			dispatch(onHoldSIPTransferCall(eid, conn, isHold));
		},
		toggleDtmfKeypad: (value) => {
			dispatch(showDtmfKeyboard(value));
		},
		toggleExternalKeypad: () => {
			dispatch(showExtTransferKeypad(true));
		},
		closeXferSipCall: (conn, callStatus) =>{
			dispatch(closeXferSip(conn, callStatus));
		},
		closeSipCall: (conn, callStatus, closePopup, outgoing, isCurrentErrand) =>{
			if(outgoing) {
				if(isCurrentErrand) {
					dispatch(toggleSipOutgoingPopup(false, ""));
					dispatch(toggleManualOrCallPopup(MP_NONE, true));
					dispatch(sipMakeCallFromErrand(false, 0));
					dispatch(resetOutboundErrandId());
				} else {
					if(closePopup) {
						//remain opened after hangup
					} else {
						dispatch(closeManualCallView());
					}
				}
			} else {
				dispatch(closeIncomingSip(conn, callStatus, closePopup));
				dispatch(showSipAgentList(false));
			}
		},
		resetSipTimer: (val) =>{
			dispatch(resetCallTimer(val));
		},
		pauseSipCall: (eid, conn, isHold) =>{
			dispatch(onHoldSIPCall(eid, conn, isHold));
		},
		recordSipCall: (eid, sipPhone, doRecording) =>{
			if(sipPhone != null){
				if(sipPhone.conn.backendRecording == true){
					sipPhone.conn.pauseBackendSipRecording(doRecording);
					return;
				}
				if(sipPhone.conn.autoRecording == true){
					playBeepNotif();
					dispatch(pauseRecordingSip());
					return;
				}
				if(doRecording) {
					dispatch(startRecordingSip(eid,
						sipPhone.conn.getStreams(), false))
				} else {
					dispatch(stopRecordingSip(eid,
						sipPhone.conn.getStreams()))
				}
			}
		},
		onApiCallClick: () => {
			dispatch(makeOutboundAPICall(false));
		},
		onApiHangupClick: () => {
			dispatch(makeHangupAPICall());
		},
		onMaximizeOutgoingCall: () => {
			dispatch(toggleSipOutgoingPopup(false, false, ""));
			dispatch(toggleWorkflowPopup(MP_MAXIMIZE));
		},
		commonApiCall: (type, num, isHold, duration) =>{
			dispatch(makeCommonAPICall(type, num, isHold, duration, "","",
					false));
		},
		openStatistics: id => {
			dispatch(statisticsOnLoad())
			.then(r => {
				dispatch(loadSavedReport(id));
			});
		},
		openAccounts: (id, link) => {
			let view = ACCOUNTS_VIEW_SLICE[id];
			dispatch(push(link)).then(() => dispatch(changeAdminView(view)));
		},
		onLinkChange: (id, link) => dispatch(push(link)).
			then(() => dispatch(changeAdminView(id))),
		onClickPreferences: () => dispatch(showAgentPreferences()),
		openAnnouncement: (id) => {
			dispatch(fetchAnnouncement(id))
			dispatch(toggleShowAnnouncement(true));
		}
	}
};

const HeaderCtnr = branch(
	({ ready }) => !ready
	// , renderNothing
	, renderSkeletonHeader
)(connect(
	mapStateToProp
	, mapCallbacks
)(Header));

export default HeaderCtnr;
