// constants for call domain that can be used accross most folders regardless
// separation concern. PLEASE ARRANGE IN ALPHANUMERIC WHENEVER POSSIBLE.
import { I } from './config';
import {
	centionGreen
	, centionRed
	, centionYellow
 } from '../../styles/_variables'

export const wf_PhoneNumber_TYPE_TWILIO = 1; // TODO: pull this from backend

// i18n texts ------------------------------------------------------------------
export const TXT_AVAILABLE = I("Available")
	, TXT_AWAY = I("Away")
	, TXT_BUSY = I("Busy")
	, TXT_CONNECT_COLON = I("Connect:")
	, TXT_ID_COLON = I('ID:')
	, TXT_NAME_COLON = I('Name:')
	, TXT_NO_CALL = I("No call")
	, TXT_STATUS_COLON = I("Status:")
	, TXT_STATUS_ID_COLON = I("Status ID:")
	, TXT_UNKNOWN = I("Unknown")
	;
export const txtActionWip = I("Previous action still in progress.")
	, txtCallNotReady = I("Call not ready. Checking browser compatibility.")
	, txtChooseAgentForCallXfer = I("Choose agent for call transfer")
	, txtChooseCallXferTarget = I("Choose call transfer target")
	, txtConnected = I('Connected')
	, txtConnecting = I('Connecting...')
	, txtConnectingServer = I('Connecting to server')
	, txtConnectionExist = I("The connection exists in another place. Click Replace button to move the connection here.")
	, txtDisconnected = I('Disconnected')
	, txtErrorLoadTwilioLib = I('Error in loading Twilio library')
	, txtErrorConnectServer = I('Error when connecting to server. Please check the console.')
	, txtIncomingTransferToAgent = I('Incoming call on hold. Click transfer to confirm.')
	, txtOutgoingTransferToAgent = I('Outgoing call on hold. Click transfer to confirm.')
	, txtIENotSupported = I('IE is not supported')
	, txtInvalidCreateOutboundErrandOption = I('Invalid create outbound errand option.')
	, txtLoadingTwilioLib = I('Loading Twilio library')
	, txtNameJoinedWarmXfer = I("{NAME} joined attended transfer.")
	, txtNameLeft = I("{NAME} left.")
	, txtNameRejectedWarmXfer = I("{NAME} rejected attended transfer call.")
	, txtNoOtherAgent = I('No other agent')
	, txtNoSelectedAgent = I("No agent selected")
	, txtNoSelectedCallerId = I("No selected caller id.")
	, txtNoTransferTarget = I('No agents available')
	, txtNoToken = I("No token")
	, txtPreviousCallNoCreateErrand = I("Errand for previous call ({CALL_SID}) is still not created.")
	, txtPreviousErrand = I("Previous errand")
	, txtReplacing = I("Replacing")
	, txtSafariNotSupported = I('Unsupported Safari version')
	, txtSignedOutCall = I("Signed-out from call")
	, txtTransferCallToAgent = I('Call transfer')
	, txtUnknownConnected = I("Unknown error")
	, txtWarmXferingToName = I("Attended transfer to {NAME}")
	, txtErrandNotCreated = I("Errand not created")
	;
const txtAccept = I("Accept")
	, txtCancel = I("Cancel")
	, txtErrand = I("Errand")
	, txtForward = I('Forward')
	, txtHangUp = I("Hang up")
	, txtOk = I("Ok")
	, txtReconnectToTwilio = I('Reconnect to Twilio server')
	, txtRefresh = I("Refresh")
	, txtReject = I("Reject")
	, txtReplace = I("Replace")
	, txtReset = I("Reset")
	, txtRetry = I("Retry")
	, txtTransfer = I('Transfer')
	;
// -----------------------------------------------------------------------------
// special renderer
export const CALL_NOT_READY = 0;
export const CHECK_SAFARI11 = true;
export const DBG_TWILIO = true;
export const KO_ACK = 'error: '
	, OK_ACK = 's-ack: '
	;
export const TWILIO_TOKEN_EXPIRED_ERROR_CODE = 31205;
export const TWILIO_SOCKET_NAMESPACE = "/twilio"
// events
export const evtACCEPT = 'accept'
	, evtAGENTS_CHANGES = 'agents changes'
	, evtCALL_AGENTS = 'call agents'
	, evtCLIENT = 'client'
	, evtMY_CHANGES = 'my changes'
	, evtMY_STATUS = 'my status'
	, evtOUTBOUND = 'outbound'
	, evtRECONNECTING = 'reconnecting'
	, evtREFRESH_TOKEN = 'refresh token'
	, evtREPLACE_WORKPLACE = 'replace workplace'
	, evtTRANSFER = 'transfer call'
	;
 // accept state
export const AS_UNKNOWN = 0
	, AS_CONFIRM_YES = 1
	, AS_CONFIRM_NO = 2
	;
// call type
export const CALLT_UNKNOWN = 0
	, CALLT_INBOUND = 1
	, CALLT_OUTBOUND = 2
	;
// call state
export const CALLS_NO_TOKEN = 0
	, CALLS_GETTING_TOKEN = 1
	, CALLS_CONNECTING_REMOTE = 2
	, CALLS_IDLE = 3
	, CALLS_OUTBOUNDING = 4
	, CALLS_CONNECTING = 5
	, CALLS_CONNECTED = 6
	, CALLS_ON_HOLD = 7
	;
//SIPP over websocket call state
export const SIP_DISCONNECTED = 0
	, SIP_CONNECTED = 1
	, SIP_NEW_RTC_SESSION = 2
	, SIP_REGISTER = 3
	, SIP_UNREGISTER = 4
	, SIP_REGISTER_FAIL = 5
	, SIP_CALL_CONNECTING = 6
	, SIP_CALL_CONNECTED = 7
	, SIP_CALL_IDLE = 8
	, SIP_CALL_ON_HOLD = 9
	, SIP_CALL_WAITING_CONNECTION = 10
	, SIP_OUTGOING_CALL_CONNECTING = 11
	, SIP_OUTGOING_CALL_CONNECTED = 12
	;
// api call type
export const CALL_STR_HOLD = "hold"
	, CALL_STR_INVITE = "invite"
	, CALL_STR_ACCEPT = "accept"
	, CALL_STR_ESTABLISH = "establish"
	, CALL_STR_END = "end"
	, CALL_STR_CANCELED = "cancel"
	, CALL_STR_DURATION = "duration"
	, CALL_STR_TRANSFER = "transfer"
	, CALL_STR_SNOOP = "snoop"
	;

// transfer call type
export const COLD_TRANSFER = "cold_transfer"
	, WARM_TRANSFER = "warm_transfer"
	;
// simpler call status
export const SCS_NOT_READY_OR_ERROR = 1
	, SCS_IDLE = 2
	, SCS_CALL_WIP = 3
	, SCS_INCOMING_CALL = 4
	;
// transfer method
export const TM_AGENT_ID = 1
	, TM_PHONE_NUMBER = 2
	;
// agent state
export const AGS_IDLE = 0
	, AGS_ASSIGNING = 1
	, AGS_BUSY = 2
	, AGS_INACTIVE = 3
	;
// agent event
export const AE_NEW_LOGIN = 0
	, AE_REMOVED = 1
	, AE_CONNECT = 2
	, AE_ACCEPT = 3
	, AE_STATUS = 4
	, AE_CALLING = 5
	;
// call acceptance state
export const ACCEPT_ST_UNKNOWN = 0
	, ACCEPT_ST_YES = 1
	, ACCEPT_ST_NO = 2
	;
// call readiness state
export const RS_CHCK_BRWSR = 0
	, RS_STPD_BRWSR = 1
	, RS_NO_SAFARI = 2
	, RS_LOADING_LIB = 3
	, RS_CONNECTING_SERVER = 4
	, RS_CHECKING_ACCEPT = 5
	, RS_READY = 6
	, RS_FAILED_LIB = 7
	, RS_FAILED_SERVER = 8
	;
// transfer state
export const XFER_ST_INVITE = 0
	, XFER_ST_JOIN = 1
	, XFER_ST_LEAVE = 2
	, XFER_ST_REJECT = 3
	;
// button type - the number can change and not important. Arrange in
// alphanumberic more important.
export const BT_ACCEPT = 1
	, BT_CANCEL = 2
	, BT_CANCEL_OPEN_ERRAND = 3
	, BT_CANCEL_XFER = 4
	, BT_ERRAND = 5
	, BT_FORWARD = 6
	, BT_HANG_UP = 7
	, BT_RECONNECT_TO_TWILIO = 8
	, BT_REFRESH = 9
	, BT_REJECT = 10
	, BT_REJECTED_OK = 11
	, BT_REPLACE = 12
	, BT_RESET = 13
	, BT_RETRY = 14
	, BT_TRANSFER = 15
	;
// color usage type
export const errorColor = centionRed
	, goodColor = centionGreen
	, warnColor = centionYellow
	;
// button size - TODO: make this sizing possible
const BS_SM = 1
	, BS_LARGE = 2
	;
// buttons map
export const buttonsMap = {
	[BT_ACCEPT]: {
		"data-qa-id": "accept"
		, text: txtAccept
	}
	, [BT_CANCEL]: {
		color: warnColor
		, "data-qa-id": "cancel"
		, size: BS_SM
		, text: txtCancel
	}
	, [BT_CANCEL_OPEN_ERRAND]: {
		countdown: true
		, "data-qa-id": "cancel_open_errand"
		, text: txtErrand
	}
	, [BT_CANCEL_XFER]: {
		"data-qa-id": "cancel_transfer"
		, text: txtCancel
	}
	, [BT_ERRAND]: {
		"data-qa-id": "errand"
		, text: txtErrand
	}
	, [BT_FORWARD]: {
		"data-qa-id": "forward"
		, size: BS_SM
		, text: txtForward
	}
	, [BT_HANG_UP]: {
		color: warnColor
		, "data-qa-id": "hangup"
		, size: BS_SM
		, text: txtHangUp
	}
	, [BT_RECONNECT_TO_TWILIO]: {
		color: warnColor
		, "data-qa-id": "reconnect"
		, size: BS_SM
		, text: txtReconnectToTwilio
	}
	, [BT_REFRESH]: {
		"data-qa-id": "refresh"
		, size: BS_SM
		, text: txtRefresh
	}
	, [BT_REJECT]: {
		color: warnColor
		, "data-qa-id": "reject"
		, size: BS_SM
		, text: txtReject
	}
	, [BT_REJECTED_OK]: {
		"data-qa-id": "reject_ok"
		, text: txtOk
	}
	, [BT_REPLACE]: {
		"data-qa-id": "replace"
		, text: txtReplace
	}
	, [BT_RESET]: {
		"data-qa-id": "reset"
		, size: BS_SM
		, text: txtReset
	}
	, [BT_RETRY]: {
		color: warnColor
		, "data-qa-id": "retry"
		, size: BS_SM
		, text: txtRetry
	}
	, [BT_TRANSFER]: {
		"data-qa-id": "transfer"
		, size: BS_SM
		, text: txtTransfer
	}
};
